<template>
    <el-container>
        <el-main>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>供片系统管理方</el-breadcrumb-item>
                <el-breadcrumb-item>设备管理</el-breadcrumb-item>
                <el-breadcrumb-item>设备列表</el-breadcrumb-item>
            </el-breadcrumb>
            <SearchForm v-if="searchOptions.open" v-model="searchOptions.searchForm" :items="searchOptions.items" @search="handleSearch" :params="params">
                <el-button v-if="editOptions.open && !selector" type="primary" @click="editFormVisible = true">添加数据</el-button>
            </SearchForm>
            <template v-if="tableOptions.open">
                <el-table :data="tableOptions.tableData" stripe border size="small"
                           header-cell-class-name="table_header"
                          cell-class-name="table_cell" style="width: 100%">
                    <el-table-column type="index" label="序号" width="50"/>
                    <template v-if="tableOptions.items">
                        <template v-for="item in tableOptions.items">
                            <el-table-column v-if="item.type === Search_Type.Select" :label="item.label">
                                <template slot-scope="scope">
                                    <template v-if="item.props">
                                        <template v-if="item.props[scope.row[item.name]]">
                                            <template v-if="item.propsColors && item.propsColors[scope.row[item.name]]">
                                                <el-tag :type="item.propsColors[scope.row[item.name]]" disable-transitions>
                                                    {{item.props[scope.row[item.name]]}}
                                                </el-tag>
                                            </template>
                                            <template v-else>
                                                {{item.props[scope.row[item.name]]}}
                                            </template>
                                        </template>
                                        <template v-else-if="scope.row[item.name]">
                                            <el-tag type="danger" disable-transitions>
                                                {{scope.row[item.name]}}
                                            </el-tag>
                                        </template>
                                        <template v-else>
                                            {{scope.row[item.name]}}
                                        </template>
                                    </template>
                                    <template v-else>
                                        <el-tag type="warning" disable-transitions>
                                            {{scope.row[item.name]}}
                                        </el-tag>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column v-else-if="item.type === Search_Type.DynamicSelect" :label="item.label">
                                <template slot-scope="scope">
                                    <el-tag :type="g_getDynamicValue(scope.row[item.name],item, true)"
                                            disable-transitions>{{g_getDynamicValue(scope.row[item.name],item)}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column v-else :prop="item.name" :label="item.label"/>
                        </template>
                    </template>
                    <el-table-column min-width="75" label="操作">
                        <template slot-scope="scope">
                            <template v-if="!selector">
<!--                                <el-button type="text" @click="openMenu(scope.row.id)">-->
<!--                                    子菜单 <i class="el-icon-school"></i>-->
<!--                                </el-button>-->
<!--                                <el-divider direction="vertical"></el-divider>-->
                                <template v-if="editOptions.open">
                                    <el-button type="text" @click="editHandle(scope.row.id)">
                                        修改 <i class="el-icon-edit"></i>
                                    </el-button>
                                    <el-divider direction="vertical"></el-divider>
                                </template>
                                <template v-if="detailOptions.open">
                                    <el-button type="text" @click="openDetail(scope.row.id)">
                                        详细 <i class="el-icon-document"></i>
                                    </el-button>
                                    <el-divider direction="vertical"></el-divider>
                                </template>
                                <el-button v-if="scope.row.disable === 0" type="text" @click="enableHandle(scope.row.id)">
                                    启用 <i class="el-icon-open"></i>
                                </el-button>
                                <el-button v-if="scope.row.disable === 1" type="text" @click="disableHandle(scope.row.id)">
                                    停用 <i class="el-icon-turn-off"></i>
                                </el-button>
                                <el-button v-if="scope.row.platform !== null && scope.row.platform !== undefined" type="text" @click="detachfromplatform(scope.row.id)">
                                    从平台解绑 <i class="el-icon-turn-off"></i>
                                </el-button>
                                <el-button type="text" @click="switchPlatform(scope.row.id,scope.row.platformId)">
                                    切换平台 <i class="el-icon-switch-platform"></i>
                                </el-button>
                            </template>
                            <template v-else>
                                <el-button type="text" @click="selectHandle(scope.row)">
                                    选择 <i class="el-icon-check"></i>
                                </el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 20, 50, 100]"
                        :current-page="tableOptions.page"
                        :page-size="tableOptions.limit"
                        :total="tableOptions.total">
                </el-pagination>
            </template>
            <!--popup search-->
            <el-dialog title="子菜单" :visible.sync="menuVisible" center width="70%" append-to-body :before-close="closeMenu">
                <Menu2_Equips :params="searchOptions.searchParams"></Menu2_Equips>
            </el-dialog>
            <el-dialog :title="editOptions.id?'修改数据':'添加数据'" :visible.sync="editFormVisible" width="85%" center append-to-body :before-close="closeEditForm">
                <EditForm v-if="editOptions.open" v-model="editOptions" :id="editOptions.id" @close="closeEditForm"></EditForm>
            </el-dialog>
            <el-dialog :visible.sync="detailPanelVisible" center width="70%" append-to-body :before-close="closeDetailPanel">
                <DetailPanel v-if="detailOptions.open" v-model="detailOptions" :id="detailOptions.id" title="详细信息"></DetailPanel>
            </el-dialog>
            <el-dialog title="选择运营平台" :visible.sync="platVisible" center width="70%" append-to-body :before-close="closePlat">
                <Menu3_Platform :selector="true" @onselect="selectJyPlatformCallbackHandle"></Menu3_Platform>
            </el-dialog>

        </el-main>
    </el-container>
</template>

<script>
    import SearchForm from "../common/OptionalSearchForm";
    import Menu2_Equips from "./Menu2_Equips";
    import EditForm from "../common/EditForm";
    import DetailPanel from "../common/DetailPanel";
    import {Search_Type} from "../../constants";
    import Menu3_Platform from "./Menu3_Platform";
    const prefix = '/equip';
    const getListUrl = `${prefix}/list`;
    const getOneUrl = (id) => `${prefix}/one/${id}`
    const getDetailUrl = (id) => `${prefix}/detail/${id}`
    const postUrl = `${prefix}/save`;
    const putUrl = (id) => `${prefix}/update/${id}`
    const enableUrl = (id)=>`${prefix}/enable/${id}`
    const disableUrl = (id)=>`${prefix}/disable/${id}`
    const detachfromplatformUrl = (id)=>`${prefix}/detachfromPlatform/${id}`
    const swithchfromPlatformUrl = `${prefix}/swithchfromPlatform`
    
    export default {
        components: {SearchForm,Menu2_Equips,EditForm,DetailPanel,Menu3_Platform},
        data(){
            return{
                Search_Type,
                tableOptions:{
                    tableData:[],
                    page: 1,
                    limit: 10,
                    total: 0,
                },
                searchOptions:{
                    searchForm: {},
                    searchParams:{},
                },
                editOptions: {
                    postUrl: postUrl,
                    getOneUrl: getOneUrl,
                    putUrl: putUrl,
                },
                detailOptions: {
                    getDetailUrl : getDetailUrl,
                },
                switchformdto: {
                    id: '',
                    platformId: '',
                },
                editFormVisible: false,
                menuVisible: false,
                detailPanelVisible: false,
                platVisible:false,
                currentPlatformid:null,
            }
        },
        props:['params','selector'],
        created() {
            this.g_setupMetadataConfig(this, 'Menu2_Equips')
            if(!this.params){
                if(this.tableOptions.items.some(item=>item.type === Search_Type.DynamicSelect)){
                    this.loadDynamicSelectData()
                }else{
                    this.getTableData();
                }
            }
        },
        methods: {
            loadDynamicSelectData(){
                let needLoad = true;
                //加载动态键值对
                this.tableOptions.items.filter(item=>item.type === Search_Type.DynamicSelect).forEach(item=>{
                    let dynamicUrl = item.dynamicUrl;
                    if(!dynamicUrl || item.props) return;
                    needLoad = false
                    this.$axios.get(dynamicUrl).then(res=>{
                        try {
                            let params = item.dynamicParams.split(',')
                            if(params.length !== 2) throw 'error parameter num'
                            item.p1 = params[0]
                            item.p2 = params[1]
                            item.props = res.data
                        }catch (e) {
                            console.log(e)
                        }
                        this.getTableData()
                    })
                })
                if(needLoad) this.getTableData()
            },
            getTableData(){
                this.$axios.get(getListUrl,{
                    params: {
                        ...this.g_getPage(this.tableOptions.page,this.tableOptions.limit),
                        ...this.searchOptions.searchForm
                    }
                }).then(res=>{
                    this.tableOptions.tableData = res.data.records;
                    this.tableOptions.page = res.data.current;
                    this.tableOptions.limit = res.data.size;
                    this.tableOptions.total = res.data.total;
                })
            },
            handleSizeChange(limit){
                this.tableOptions.limit = limit;
                this.getTableData();
            },
            handleCurrentChange(page){
                this.tableOptions.page = page;
                this.getTableData();
            },
            handleSearch(){
                this.tableOptions.page = 1;
                this.getTableData();
            },
            enableHandle(id){
                this.$axios.put(enableUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData();
                        }
                    });
                })
            },
            disableHandle(id){
                this.$axios.put(disableUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData();
                        }
                    });
                })
            },
            detachfromplatform(id){
                this.$axios.put(detachfromplatformUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData();
                        }
                    });
                })
            },
            switchPlatform(id,platformId) {
                // 这里可以弹出可选平台列表，并让用户选择一个平台
                // 假设选择的平台 ID 存储在变量 selectedPlatformId 中
                // let selectedPlatformId = ""; // 从列表中选择的平台的 ID
                
                // 打开选择平台的弹窗
                this.platVisible = true;
                this.switchformdto.id = id;
                this.currentPlatformid = platformId;
            },
            switchPlatformHandle(id) {
                // // 将当前行的 ID 传递给弹窗组件，以便处理
                this.selectedPlatformId = id;
                // alert(id);
                if (this.selectedPlatformId !== this.currentPlatformid) {
                    // 如果选择的平台和当前平台不同，则进行切换
                    this.switchformdto.platformId = this.selectedPlatformId;
                    this.$axios.post(swithchfromPlatformUrl, this.switchformdto).then(() => {
                        this.$message({
                            showClose: true,
                            message: `切换平台成功`,
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.getTableData();
                            }
                        });
                    });
                } else {
                    // 如果选择的平台和当前平台相同，则不执行任何操作，或者提示用户无需切换
                    this.$message.info('当前设备已经在选择的平台上');
                }
            },
            selectJyPlatformCallbackHandle(data){
              this.switchPlatformHandle(data.id);
              this.platVisible = false
            },
            closeMenu(done){
                this.searchOptions.searchParams = []
                done();
            },
            closePlat(done){
                this.searchOptions.searchParams = []
                done();
            },
            openMenu(id){
                this.menuVisible = true;
                this.searchOptions.searchParams = [{name:'id',value:id}];
            },
            openDetail(id){
                this.detailOptions.id = id;
                //this.detailOptions.initData = row
                this.detailPanelVisible = true
            },
            editHandle(id){
                this.editOptions.id = id
                this.editFormVisible = true
            },
            closeEditForm(result){
                this.editOptions.id = null
                this.editFormVisible = false
                if(result){
                    this.getTableData()
                }
            },
            closeDetailPanel(){
                this.detailOptions.id = null
                this.detailPanelVisible = false
            },
            selectHandle(row){
                this.$emit('onselect', row)
            }
        }
    }
</script>

<style scoped>

</style>